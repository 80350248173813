<template>
    <div class="edit" style="padding: 15px">
        <div v-if="!edit">
            <div style="text-align: center; color: #606266; font-size: 16px; line-height: 55px">套餐服务项</div>
            <el-form class="packageEditForm" label-position="left" ref="form" :model="formTwo" size="small"
                label-width="500px" style="width: 100%">
                <div v-for="(item, index) in p_c" :key="index">
                    <el-form-item :label="item.classify_name" style="
                            display: flex;
                            align-items: center;
                            background-color: rgba(207, 225, 245, 1);
                            height: 40px;
                            margin: 0;
                            border-bottom: 1px solid #ebeff0;
                            color: #cdcef3;
                        ">
                    </el-form-item>
                    <el-checkbox-group v-model="formTwo.type[item.classify_id]">
                        <div style="
                                display: flex;
                                align-items: center;
                                flex-wrap: wrap;
                                color: #cdcef3;
                                flex-direction: row;
                            ">
                            <el-checkbox v-for="(item1, index1) in item.module" :label="item1.module_id" :key="index1">
                                {{ item1.module_name }}
                            </el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div>
                <el-form-item style="margin: 20px 0; text-align: center">
                    <el-button size="medium" style="width: 120px" type="primary" @click="onSubmit('softsave')">{{
                    btnMsg
                    }}</el-button>
                    <el-button size="medium" style="width: 120px; margin-left: 20px" @click="this.$parent.status = 1">取消
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <EditPackageMsg v-if="edit" :dataArr="dataArr" @closeEdit="closeEdit()"></EditPackageMsg>
    </div>
</template>
<script>
import { index_config, get_package } from '../../../common/js/package';
import EditPackageMsg from './EditPackageMsg';
export default {
    name: 'EditPackage',
    components: {
        EditPackageMsg
    },
    props: {
        editRow: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            btnMsg: '创建套餐',
            edit: false,
            formTwo: {
                type: [],
                name: '',
                region: '',
                desc: ''
            },
            p_c: [],
            dataArr: [],
            basic: {
                id: ''
            },
            Menu: {}
        };
    },
    methods: {
        closeEdit() {
            this.dataArr = [];
            // this.edit = false;
            // this.formTwo = {
            //     type: [],
            //     name: '',
            //     region: '',
            //     desc: ''
            // };
            this.$parent.status = 1;
        },
        getMsg(value) {
            // 所有服务项
            index_config().then(res => {
                this.p_c = res.result;
                let { result } = res;
                for (var i = 0; i < result.length; i++) {
                    this.formTwo.type[result[i].classify_id] = [];
                }
                if (this.editRow.id) {
                    let dt = { menu_id: this.editRow.id };
                    get_package(dt).then(res1 => {
                        this.basic = res1.result.basic;
                        for (var c = 0; c < res1.result.classify_model.length; c++) {
                            this.formTwo.type[res1.result.classify_model[c].classify_id] =
                                res1.result.classify_model[c].module_ids;
                        }
                        // console.log(res1.result.Menus);
                        this.Menu = res1.result.Menus;
                        this.btnMsg = '编辑套餐';
                    });
                }
            });
        },
        onSubmit() {
            let p = this.p_c;
            let t = this.formTwo.type;
            let arr = [];
            t.forEach((element, index) => {
                for (var j = 0; j < p.length; j++) {
                    if (index == p[j].classify_id) {
                        element.forEach(ele => {
                            for (var z = 0; z < p[j].module.length; z++) {
                                if (p[j].module[z].module_id == ele) {
                                    p[j].module[z].classify_id = index;
                                    if (p[j].module[z].module_unit == 2) {
                                        // console.log('11',p[j].module[z],this.Menu)
                                        for (let key in this.Menu) {
                                            // console.log(this.Menu[key]);
                                            if (this.Menu[key].configure_id == ele) {
                                                p[j].module[z].number = this.Menu[key].number;
                                            }
                                        }
                                    }
                                    arr.push(p[j].module[z]);
                                }
                            }
                        });
                    }
                }
            });

            if (this.basic.id) {
                this.dataArr.id = this.basic.id;
                this.dataArr.menu_name = this.basic.menu_name;
                this.dataArr.menu_duration = this.basic.menu_duration;
                this.dataArr.Menu = this.Menu;
                this.dataArr.Discount = this.basic.Discount;
            }
            if (!arr.length) {
                this.$message.error('请选择套餐服务项');
                return false;
            }
            this.dataArr.data = arr;
            this.edit = true;
        }
    },
    mounted() {
        this.getMsg();
    }
};
</script>
<style lang="scss" scope>
.packageEditForm .el-checkbox {
    width: 260px;
    margin: 20px 0 !important;
}

.packageEditForm .el-form-item__content {
    margin: 0 !important;
}

.packageEditForm .el-form-item--small .el-form-item__label {
    padding-left: 25px;
}

.packageEditForm .el-checkbox__label {
    display: inline-block;
    padding-left: 10px;
    line-height: 19px;
    font-size: var(--el-checkbox-font-size);
    word-break: normal;
    /*width: auto;*/
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    vertical-align: top;
}

// .edit {
//     border: 1px solid #000;
//     // width: 1000px;
// }
</style>
