<template>
    <div>
        <PackageIndex v-if="status == 1" @changeStatus="changeStatus"></PackageIndex>
        <EditPackage v-if="status == 2" :editRow="editRow" @changeStatus="changeStatus"></EditPackage>
    </div>
</template>
<script>
import PackageIndex from "./components/PackageIndex";
import EditPackage from "./components/EditPackage";
export default {
    components: {
        PackageIndex,
        EditPackage
    },
    data() {
        return {
            status:1,
            editRow:{},
        };
    },
    created() {

    },
    methods: {
        changeStatus(val){
            this.status = val.status;
            this.editRow = val.row;

        }
    }
};
</script>
<style>

</style>