<template>
    <!-- 用户信息 -->
    <div class="package_Index">
        <el-row>
            <div style="margin-top: 15px">
                <el-form size="small" :inline="true" :model="formInline" class="demo-form-inline"
                    style="display: flex; justify-content: space-between">
                    <!-- <el-col :span="16">
                        <el-form-item label="名称">
                            <el-input v-model="formInline.input" placeholder="请输入内容"></el-input>
                        </el-form-item>
                        <el-form-item label="状态">
                            <el-select style="width: 120px" v-model="formInline.user" placeholder="职位状态">
                                <el-option label="所有" value="2"></el-option>
                                <el-option label="停用" value="0"></el-option>
                                <el-option label="正常" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-button style="margin-left: 10px" @click="init()" type="primary" size="small"
                        >查询</el-button
                        >
                    </el-col> -->
                </el-form>
                <div style="text-align: right">
                    <el-button style="height: 32px !important" type="primary" size="small"
                        :disabled="!permissionsAction.add" @click="addPackage()" class="koaz">新建</el-button>
                </div>
            </div>
            <div style="width: 100%; margin-top: 20px">
                <el-table :header-cell-style="{ background: '#F2F2F2' }" :data="tableData" border
                    style="width: 100%; text-align: center" :index="indexMethod">
                    <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
                    <el-table-column align="center" prop="menu_name" label="套餐名称"></el-table-column>
                    <el-table-column width="200" align="center" prop="menu_duration" label="套餐时长（天）"></el-table-column>
                    <el-table-column width="200" align="center" prop="menu_amount" label="套餐金额（元）"></el-table-column>
                    <el-table-column width="200" align="center" prop="final_amount" label="优惠后金额（元）"></el-table-column>

                    <!-- <el-table-column align="center" prop="billing_unit" label="计费单位">
                        <template #default="scope">
                            <div v-if="scope.row.billing_unit == 1">天</div>
                            <div v-if="scope.row.billing_unit == 2">个</div>
                        </template>
                    </el-table-column> -->
                    <el-table-column width="200" align="center" prop="create_time" label="创建时间"></el-table-column>
                    <el-table-column width="150" align="center" prop="status" label="状态">
                        <template #default="scope">
                            <div v-if="scope.row.status == 1">启用</div>
                            <div v-else>停用</div>
                        </template>
                    </el-table-column>
                    <el-table-column width="200" align="center" label="操作">
                        <template #default="scope">
                            <!-- :disabled="!permissionsAction.edit" -->

                            <el-button @click="editClick(scope.row)" type="text" size="small">编辑</el-button>
                            <!-- :disabled="!permissionsAction.enable" -->

                            <el-button @click="disableClick(scope.row)" type="text" size="small">{{
                            scope.row.status == 1 ? '停用' : '启用'
                            }}</el-button>
                            <!-- :disabled="!permissionsAction.send" -->

                            <el-button type="text" size="small" @click="sendDia(scope.row)">发送</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: center; margin-top: 25px">
                    <el-pagination background v-model:currentPage="page.page" v-model:page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.totalItems"
                        @size-change="handleSizeChange" @current-change="handleCurrentChange">
                    </el-pagination>
                </div>

                <el-dialog title="发送套餐" v-model="dispenseaccount" width="450px" center>
                    <div>
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="right"
                            label-width="100px">
                            <el-form-item label="编号：" prop="title">
                                <div>MXTX0005</div>
                            </el-form-item>
                            <el-form-item label="企业名称：" prop="region">
                                <el-select v-model="ruleForm.region" filterable placeholder="请选择职位类型">
                                    <el-option v-for="(item, index) in countassmeg" :key="index" :label="item.name"
                                        :value="item.id + ',' + item.name"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-button @click="sendClick" style="width: 30%; margin: 20px 0 0 35%" size="small"
                                type="primary">确定</el-button>
                        </el-form>
                    </div>
                </el-dialog>
            </div>
        </el-row>
    </div>
</template>
<script>
import { enable_package, idsort_package, index_package, send_package, index_corp } from '../../../common/js/package';

export default {
    name: 'PackageIndex',
    components: {},
    data() {
        return {
            ruleForm: {
                title: '',
                region: ''
            },
            rules1: {},
            countassmeg: [],
            edit_id: '',
            dialogVisible: false,
            dispenseaccount: false,
            formInline: {
                user: '2',
                input: ''
            },
            packageSort: [],
            // 表格数据
            tableData: [],
            // 分页
            page: {
                page: 1, // 当前页
                pageSize: 10,
                totalItems: 0
            },
            // 权限操作集合
            permissionsAction: {}
        };
    },
    created() {
        this.init();
        this.getMsg();
    },
    methods: {
        sendDia(row) {
            index_corp().then(res => {
                this.countassmeg = res.result;
                this.edit_id = row.id;
                this.dispenseaccount = true;
            });
        },
        addPackage() {
            this.$emit('changeStatus', { status: 2 });
            this.init();
        },
        // 发送套餐
        sendClick() {
            // console.log(this.edit_id);
            // console.log(this.ruleForm.region.split(','));
            send_package({
                p_id: this.edit_id,
                e_id: this.ruleForm.region.split(',')[0],
                e_name: this.ruleForm.region.split(',')[1]
            }).then(res => {
                this.$message.success(res.msg);
                this.ruleForm = {
                    title: '',
                    region: ''
                };
                this.dispenseaccount = false;
            });
        },
        handleClose() {
            this.$refs.ruleForm.resetFields();
            this.dialogVisible = false;
            this.init();
        },
        // 停用套餐
        disableClick(row) {
            let actionName = row.status == 1 ? '停用' : '启用';
            this.$confirm('', `确认${actionName}此套餐？`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true,
                type: 'warning',
                center: true
            }).then(() => {
                let dt = {
                    menu_id: row.id,
                    status: row.status == 1 ? 0 : 1
                };
                enable_package(dt).then(res => {
                    this.$message.success(res.msg);
                    this.init();
                });
            });
        },
        // 新建/编辑套餐
        editClick(row) {
            this.$emit('changeStatus', { status: 2, row: row });
        },
        // 分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.page.page = 1;
            this.init();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            this.init();
        },
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        init() {
            this.dt = {
                page: this.page.page,
                size: this.page.pageSize
            };
            index_package(this.dt).then(res => {
                this.tableData = res.result.data;
                // let { Add: add, Edit: edit, Enable: enable, Send: send } = res.attach.Power;
                this.permissionsAction = res.attach.Power;
                this.page.totalItems = res.result.total;
            });
        },
        getMsg() {
            idsort_package().then(res => {
                let { code, result } = res;
                if (code == 200) {
                    this.packageSort = result;
                }
            });
        }
    }
};
</script>
<style>
.package_Index .specsInput .el-icon-search:before {
    display: none;
}

.package_Index .specsInput1 .el-input__suffix-inner .el-input__icon:after {
    content: '元/天' !important;
    width: 80px;
    margin-left: -40px;
}
</style>
