<template>
    <div style="padding: 15px">
        <div style="text-align: center;color: #606266;font-size: 16px;line-height: 55px">套餐基本信息</div>
        <el-form label-position="right" ref="form" :model="ruleForm" size="medium" label-width="100px"
            style="width: 100%">
            <el-form-item label="套餐名称：">
                <el-input v-model="ruleForm.name" placeholder="请输入套餐名称" style="width: 300px;"></el-input>
            </el-form-item>
            <el-form-item label="套餐时长：">
                <el-input v-model="ruleForm.time" @change="changeMout()" placeholder="请输入套餐时长" style="width: 300px">
                </el-input>
            </el-form-item>
            <el-form-item label="已选套餐项：">
                <el-table :data="tableData" stripe border style="width: 100%">
                    <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
                    <el-table-column align="center" prop="module_name" label="模块名称"></el-table-column>
                    <el-table-column align="center" label="数量">
                        <template #default="scope">
                            <div>
                                <el-input-number style="width: calc(100% - 35px)" :disabled="scope.row.module_unit == 1"
                                    v-model="scope.row.number" :step="1" />
                                {{ scope.row.module_unit == 1 ? '天' : '个' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="module_cost" label="金额"></el-table-column>
                </el-table>
            </el-form-item>
            <el-form-item>
                <div>
                    <span style="display: inline-block;width: 50%;color: #606266">总金额：</span>
                    <span style="display: inline-block;width: 50%;text-align: right;color: red;font-size: 26px">
                        ￥{{ getAmount() }}
                    </span>
                </div>
            </el-form-item>
            <el-form-item>
                <div>
                    <span style="display: inline-block;width: 50%;color: #606266">优惠后总价：</span>
                    <span style="display: inline-block;width: 50%;text-align: right;color: red;font-size: 26px">
                        ￥<el-input v-model="ruleForm.total" style="width: 150px;vertical-align: bottom;"></el-input>
                    </span>
                </div>
            </el-form-item>
            <el-form-item style="margin: 40px 0; text-align: center">
                <el-button size="medium" style="width:120px" type="primary" @click="onSubmit()">保存</el-button>
                <el-button size="medium" style="width: 120px;margin-left: 20px" @click="cancel()">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {save_package,edit_package} from "../../../common/js/package";
    export default {
        name:'EditPackageMsg',
        props:{
            dataArr:{
                type:Array,
                defalut:() => {}
            }
        },
        data(){
            return{
                ruleForm:{
                    id:'',
                    name:'',
                    time:'',
                    total:''
                },
                tableData:[],
            }
        },
        methods:{
            cancel(){
                // this.dataArr = [];
                this.ruleForm = {
                     name:'',
                    time:'',
                };
                this.$parent.edit = false;
            },
            onSubmit(){
                let dt = {};
                if(!this.ruleForm.name){
                    this.$message.error('请输入套餐名称');
                    return;
                }
                dt.menu_name = this.ruleForm.name;
                if(!this.ruleForm.time){
                    this.$message.error('请输入套餐时长');
                    return;
                }
                dt.menu_duration = this.ruleForm.time;
                let arr = this.tableData;
                let classify_model1 = [];
                let module_numbers = {};
                for(var i=0;i<arr.length;i++){
                    if(arr[i].module_unit == 2 && !arr[i].number){
                        this.$message.error('请输入'+arr[i].module_name+'的数量');
                        return;
                    } 
                    if(classify_model1[arr[i].classify_id]){
                        classify_model1[arr[i].classify_id].push(arr[i].module_id); 
                    }else{
                        classify_model1[arr[i].classify_id] = [];
                        classify_model1[arr[i].classify_id].push(arr[i].module_id); 
                    }
                    if(arr[i].module_unit == 2){
                        module_numbers[arr[i].module_id] = arr[i].number;
                    }
                }
                let  classify_model = [];
                if(classify_model1){
                    classify_model1.forEach((ele,key)=>{
                        classify_model.push({
                            classify_id:key,
                            module_ids:ele
                        })
                    })
                }
                // console.log(classify_model1);
                dt.classify_model = classify_model;
                dt.module_numbers = module_numbers;
                dt.Discount = this.ruleForm.total ? this.ruleForm.total : this.getAmount();
                if(this.ruleForm.id){
                    dt.menu_id = this.ruleForm.id;
                    edit_package(dt).then(res => {
                        this.$message.success(res.msg);
                        this.$emit('closeEdit');
                    })
                }else{
                    save_package(dt).then(res => {
                        this.$message.success(res.msg);
                        this.$emit('closeEdit');
                    })
                }

            },
            getAmount(){
                let num = 0;
                for (var i=0;i<this.tableData.length;i++){
                    num += this.tableData[i].number*this.tableData[i].module_cost;
                }
                if(num){
                    return this.returnFloat(num);
                }else{
                    // this.ruleForm.total = '';
                    return '待计算……';
                }
            },
            returnFloat(value) {
                value = Math.round(parseFloat(value) * 100) / 100;
                var s = value.toString().split(".");
                if (s.length == 1) {
                    value = value.toString() + ".00";
                    return value;
                }
                if (s.length > 1) {
                    if (s[1].length < 2) {
                        value = value.toString() + "0";
                    }
                    return value;
                }
            },
            changeMout(){
                for(var r=0;r<this.tableData.length;r++){
                    if(this.tableData[r].module_unit == 1){
                        this.tableData[r].number = parseInt(this.ruleForm.time);
                    }
                }
            }
        },
        mounted() {
            
            this.$nextTick(()=>{
                this.tableData = this.dataArr.data;
                for(var t=0;t<this.tableData.length;t++){
                    if(this.tableData[t].module_unit == 1){
                        this.tableData[t].number = this.dataArr.menu_duration ? parseInt(this.dataArr.menu_duration) : '';
                    }
                }
                if(this.dataArr.menu_name){
                    this.ruleForm.name = this.dataArr.menu_name;
                    this.ruleForm.time = this.dataArr.menu_duration;
                }
                if(this.dataArr.id){
                    this.ruleForm.id = this.dataArr.id;
                }
                if(this.dataArr.Discount){
                    this.ruleForm.total = this.dataArr.Discount;
                }

            })
            
        
        },
        // watch:{
        //     'ruleForm.time'(val){
        //         this.changeMout(val);
        //     }
        // }
    }
</script>
<style lang="scss">

</style>